import React from "react";
import faker from "faker";
import { ColumnProps } from "antd/lib/table";
import { Tag, Typography } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import { InvoiceStatusTag } from "../invoice-status-tag/invoice-status-tag.component";
import {
  formatCurrency,
  formatPercent,
  formatDateShort
} from "../../utils/formatting";
import { Invoice } from "../../redux/invoice/invoice.types";

const { Text } = Typography;

export const invoicesTableData: Invoice[] = Array(10)
  .fill(1)
  .map(() => {
    const obj: Invoice = {
      id: faker.random.uuid(),
      date: faker.date.past(),
      title: `${faker.company.bsBuzz().toUpperCase()}-${faker.random.number(
        10
      )}`,
      to: faker.company.companyName(),
      dueDate: faker.random.boolean() ? faker.date.future() : faker.date.past(),
      total: Number(faker.finance.amount(100, 1000)),
      currency: faker.finance.currencyCode(),
      paid: 0,
      from: faker.name.findName(),
      items: Array(5)
        .fill(1)
        .map(() => ({
          _id: faker.random.uuid(),
          description: faker.commerce.product(),
          quantity: faker.random.number(10),
          rate: parseInt(faker.commerce.price(10, 100), 10)
        }))
    };

    obj.paid = faker.random.boolean()
      ? Number(faker.finance.amount(0, obj.total * 0.9))
      : faker.random.boolean()
      ? obj.total
      : 0;

    return obj;
  });

export const invoicesTableColumns: ColumnProps<Invoice>[] = [
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
    render: (text, record) => (
      <>
        <Tag>{formatDateShort(record.date)}</Tag>
        <Text strong>
          <Link to={`/invoices/${record.id}`}>{record.title}</Link>
        </Text>
        <Typography style={{ marginTop: 8 }}>To: {record.to}</Typography>
      </>
    )
  },
  {
    title: "Due date",
    dataIndex: "due_date",
    key: "due_date",
    render: (text, record) => {
      return (
        <>
          <InvoiceStatusTag invoice={record} />
          Due: {formatDateShort(record.dueDate)}
        </>
      );
    }
  },
  {
    title: "Payment info",
    dataIndex: "payment_info",
    key: "payment_info",
    render: (text, record) => (
      <Typography style={{ textAlign: "end" }}>
        {formatCurrency(record.total)} USD
        <br />
        <Text type="secondary">
          Due {moment() > moment(record.dueDate) ? "for" : "in"}{" "}
          {moment(record.dueDate).fromNow(true)} (
          {formatPercent((100 * record.paid) / record.total)} paid)
        </Text>
      </Typography>
    )
  }
];
