import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";

import { selectUserError } from "../../redux/user/user.selectors";
import { userCleanError } from "../../redux/user/user.actions";
import { selectInvoiceErrorMessage } from "../../redux/invoice/invoice.selectors";
import { invoiceCleanError } from "../../redux/invoice/invoice.actions";

export const ErrorNotification = () => {
  const userErrorMessage = useSelector(selectUserError);
  const invoiceErrorMessage = useSelector(selectInvoiceErrorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userErrorMessage) {
      message.error(userErrorMessage);
      dispatch(userCleanError());
    }

    if (invoiceErrorMessage) {
      message.error(invoiceErrorMessage);
      dispatch(invoiceCleanError());
    }
  }, [userErrorMessage, invoiceErrorMessage]);

  return null;
};
